import url from '$utils/tool/url';
export default {
    data() {
        return {
            videoSrc: '',
            imageSrc: '',
            useVideo: false
        };
    },
    computed: {
        usePoster() {
            if (this.baseURL) {
                return url.addBaseURL(this.poster, this.baseURL);
            }
            else {
                return this.poster;
            }
        }
    },
    videoSuffix: {
        'mp4': 1,
        'MP4': 1,
        'ogg': 1,
        'OGG': 1,
        'webm': 1,
        'webM': 1
    },
    methods: {
        setImageSrc(src, async = true) {
            // if (!src) return;
            if (src.indexOf('data:') === 0) {
                if (src.indexOf('data:video') === 0) {
                    return this.setVideoSrc(src);
                }
            }
            else {
                let suffix = src.substr(src.lastIndexOf('.') + 1);
                // 执行添加 url
                if (this.baseURL) {
                    src = url.addBaseURL(src, this.baseURL);
                }
                // if (this.baseURL && src.indexOf('http') !== 0) {
                //     src= this.baseURL + src;
                // }
                if (this.$options.videoSuffix[suffix]) {
                    return this.setVideoSrc(src, async);
                }
            }
            // 如果不为视频
            if (this.useVideo)
                this.useVideo = false;
            if (async) {
                return this.$nextTick(() => this.imageSrc = src);
            }
            else {
                this.imageSrc = src;
            }
        },
        setVideoSrc(src, async = true) {
            if (!this.useVideo)
                this.useVideo = true;
            if (this.video || this.poster) {
                if (async) {
                    return this.$nextTick(() => this.imageSrc = src);
                }
                else {
                    this.imageSrc = src;
                }
            }
            else {
                if (async) {
                    return this.$nextTick(() => this.videoSrc = src);
                }
                else {
                    this.videoSrc = src;
                }
            }
        },
        videoLoad() {
            let canvas = document.createElement('canvas');
            canvas.width = this.videoEl.videoWidth;
            canvas.height = this.videoEl.videoHeight;
            // @ts-ignore
            canvas.getContext('2d').drawImage(this.videoEl, 0, 0, canvas.width, canvas.height);
            try {
                let src = canvas.toDataURL('image/png');
                this.setImageSrc(src);
            }
            catch (e) {
                if (this.videoMode) {
                    let src = this.videoSrc;
                    this.$nextTick(() => this.imageSrc = src);
                }
            }
            // @ts-ignore
            canvas = null;
            this.videoSrc = '';
        }
    }
};
