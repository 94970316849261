import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Notification, Message } from 'element-ui';
import { vLocalImage } from '$components/index';
import './registerServiceWorker';
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
// @ts-ignore
// import NProgress from 'nprogress'
//
//
// // 配置NProgress进度条选项 —— 动画效果
// NProgress.configure({ ease: 'ease', speed: 500 }).render();
//
// console.log(NProgress);
//
// // 全局路由拦截-进入页面前执行
// router.beforeEach((to, from, next) => {
//   // NProgress开始进度条
//   NProgress.start()
//   next()
// })
//
// // 全局后置钩子-常用于结束动画等
// router.afterEach(transition => {
//   // NProgress结束进度条
//   NProgress.done()
//   // console.log(transition)
// })
Vue.config.productionTip = false;
Vue.component('v-local-image', vLocalImage);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
