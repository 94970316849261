import toast from "$utils/tool/toast";
import test from '$utils/test/index';
import request from '$utils/request/index';
export default {
    state: {
        type: 1,
        budget: '1-2w',
        mobile: '',
        name: '',
        message: '',
        price: ''
    },
    mutations: {
        updatedInput(state, obj) {
            if (state.hasOwnProperty(obj.key)) {
                state[obj.key] = obj.value;
            }
        }
    },
    actions: {
        // 提交
        // @ts-ignore
        submit({ state }, params = {}) {
            return new Promise(function (resolve, reject) {
                test.verificationPromise([
                    {
                        value: state.name,
                        placeholder: '请输入称呼姓名',
                        rules: '',
                        key: 'name'
                    },
                    {
                        value: state.name,
                        placeholder: '请输入称呼姓名',
                        rules: '',
                        key: 'name'
                    },
                    {
                        value: state.name,
                        placeholder: '请输入称呼姓名',
                        rules: '',
                        key: 'name'
                    },
                    {
                        value: state.mobile,
                        rules: {
                            empty: '请输入您的联系方式',
                            mobile: '请确认您的联系方式是否正确'
                        },
                        key: 'phone'
                    },
                    {
                        value: state.message,
                        key: 'content'
                    }
                ], true).then((data) => {
                    request.$request({
                        url: 'index/Index/yunkust',
                        data: {
                            ...data,
                            ...params,
                            webtype: state.type,
                            money: state.budget
                        }
                    });
                    let result = {
                        type: 1,
                        budget: '1-2w',
                        mobile: '',
                        name: '',
                        message: '',
                        price: ''
                    };
                    for (let key in result) {
                        if (result.hasOwnProperty(key) && state[key] !== result[key]) {
                            state[key] = result[key];
                        }
                    }
                    toast.$notify({
                        title: '提交成功',
                        message: '欢迎您光临，我们会在24小时内联系您!',
                        type: 'success'
                    });
                    return resolve();
                }).catch(reject);
            });
        }
    }
};
