export default {
    computed: {
        icon() {
            if (this.defaultIcon && this.status === this.imageStatus.loading) {
                return this.defaultIcon;
            }
            else if ((this.defaultIcon || this.failIcon) && this.status === this.imageStatus.fail) {
                return this.failIcon || this.defaultIcon;
            }
            else {
                return undefined;
            }
        },
        iconMode() {
            if (this.defaultIcon && this.status === this.imageStatus.loading) {
                return this.defaultMode || this.mode;
            }
            else if ((this.defaultIcon || this.failIcon) && this.status === this.imageStatus.fail) {
                return this.failMode || this.mode;
            }
            else {
                return undefined;
            }
        }
    }
};
