export default {
    state: {
        mobile: false
    },
    mutations: {
        setMobile(state, config) {
            let mobile = config.width <= 768;
            if (state.mobile !== mobile) {
                state.mobile = mobile;
            }
        }
    },
    actions: {
        installConfig({ commit }) {
            // @ts-ignore
            commit('setMobile', window.screen_conf);
            // @ts-ignore
            window.observer_size['global'] = function (config) {
                commit('setMobile', config);
            };
        }
    }
};
