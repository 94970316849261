import $config from '$config/index';
export default {
    addBaseURL(url, baseURL = $config.baseURL) {
        if (!url)
            return url;
        if (url.indexOf('data:') < 0 && url.indexOf('http') < 0) {
            url = baseURL + url;
        }
        return url;
    }
};
