import Vue from 'vue';
import VueRouter from 'vue-router';
import config from "@/config/config";
Vue.use(VueRouter);
let routes = [
    {
        path: '*',
        redirect: 'home',
    }
];
// 收容所有的page页面
const pagesContText = require.context('../pages', true, /page.ts$/);
// 设置nav收容阶段
let navs = [];
pagesContText.keys().map((item) => {
    // 获取模块
    let modal = pagesContText(item);
    // 鉴定模块
    if (modal.default) {
        // 设置router模块
        let routerPage = modal.default;
        // 判断是否存在name
        if (routerPage.name === undefined) {
            routerPage.name = getPathName(item);
        }
        // 判断是否存在 path
        if (routerPage.path === undefined) {
            routerPage.path = '/' + routerPage.name;
        }
        // 判断是否指定了 nav
        if (routerPage.meta !== undefined && routerPage.meta.nav !== undefined) {
            let nav = routerPage.meta.nav;
            if (nav.name === undefined) {
                nav.name = routerPage.name;
            }
            navs.unshift(nav);
        }
        routes.push(routerPage);
    }
});
config.navConfig = navs;
// 获取name值
function getPathName(path) {
    let paths = path.split('/');
    return paths[paths.length - 2];
}
const router = new VueRouter({
    routes
});
export default router;
