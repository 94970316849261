var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import inputData from '../data/input';
import footer from '../data/footer';
import imageOption from '../option/image';
import { vAnimateTiming, vAnimateItem } from '$components/index';
import vBackground from '$components/background';
import { vRow, vColumn } from '$components/index';
import require_image from "$utils/images/require_image";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        // 背景图
        this.background = require_image('images/background.png', imageOption);
        // 手机端背景图
        this.backgroundMobile = require_image('images/background-mobile.png', imageOption);
        this.inputData = inputData();
        this.footer = footer;
    }
    // 提交
    submit() {
        this.$store.dispatch('submit', {
            type: 1
        }).then(() => {
            this.$refs.input && this.$refs.input.map((item) => {
                item.value = '';
            });
        });
    }
    // 更新函数
    updateInput(item, value) {
        if (this.inputState[item.key] === value)
            return;
        return this.$store.commit('updatedInput', { key: item.key, value });
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true
    })
], default_1.prototype, "input", void 0);
default_1 = __decorate([
    Component({
        components: {
            vRow,
            vColumn,
            vBackground,
            vAnimateTiming,
            vAnimateItem
        },
        computed: {
            inputState() {
                return this.$store.state.input;
            }
        }
    })
], default_1);
export default default_1;
