export default {
    // 判断是否为object
    isObject(data) {
        return Object.prototype.toString.call(data) === '[object Object]';
    },
    // 判断是否为array
    isArray(data) {
        return Object.prototype.toString.call(data) === '[object Array]';
    }
};
