import config from '@/config/config';
export default {
    state: {
        // @ts-ignore
        nav: config.navConfig
    },
    mutations: {
        updateNav(state, nav) {
            if (state.nav !== nav) {
                state.nav = nav;
            }
        }
    }
};
