export default {
    /*
    *   图片地址
    *   如果为视频默认截取视频第一张， 注意 针对 跨域等问题 可根据 videoMode 最终采用 video 加载
    * */
    src: {
        type: String,
        default: ''
    },
    /* 模块 */
    module: {
        type: String,
        default: 'pages/home'
    },
    /* 默认最后一层的路径 */
    footerDir: {
        type: String,
        default: ''
    },
    /* 路径 */
    path: {
        type: [Array, String],
        default: ''
    }
};
