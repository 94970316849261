export default {
    // 针对 string 转换为 相对应的数字 目前仅支持了 %
    unit(value, max) {
        if (typeof value === 'number')
            return value;
        else
            return max * (parseFloat(value) / 100);
    },
    unitRem(value) {
        // @ts-ignore
        return value * window.screen_conf.proportion / screen_conf.multiple + 'rem';
    },
    unitPx(value) {
        // @ts-ignore
        return value * window.screen_conf.proportion;
    }
};
