var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
let vAnimateTiming = class vAnimateTiming extends Vue {
    constructor() {
        super(...arguments);
        this.draw = false;
    }
};
__decorate([
    Prop({
        type: [Array, String],
        default: 'fade'
    })
], vAnimateTiming.prototype, "timing", void 0);
__decorate([
    Inject()
], vAnimateTiming.prototype, "registerTiming", void 0);
vAnimateTiming = __decorate([
    Component({
        created() {
            this.registerTiming(() => {
                this.draw = true;
            });
        },
        computed: {
            timingClass() {
                let timings = typeof this.timing === 'string' ? [this.timing] : this.timing;
                return [...timings, 'animated'];
            }
        }
    })
], vAnimateTiming);
export default vAnimateTiming;
