<template>
  <div class="swiper-slide"><slot></slot></div>
</template>

<script>
export default {
  name: "swiper-item"
}
</script>

<style scoped lang="scss" src="../style.scss"></style>