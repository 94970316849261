import Unit from '@/utils/unit/unit';
export default {
    methods: {
        /*
        *  获取 当前移动距离
        * */
        getScrollDistance: function (direction = 'y') {
            if (this._scrollConfig[direction]) {
                return this._scrollConfig[direction][direction];
            }
            else {
                return 0;
            }
        },
        /*
        *  获取距离底部的距离
        * */
        getScrollConfig: function (direction = 'y', useAwait = true) {
            // 获取 高度
            useAwait && this.setScrollConfig();
            return this._scrollConfig[direction];
        },
        /*
        *  获取距离底部的距离
        * */
        getScrollEndDistance: function (direction = 'y') {
            let nowDistance = this.getScrollDistance(direction);
            // 获取 高度
            this.setScrollConfig();
            let config = this._scrollConfig[direction];
            if (config) {
                // 返回距离底部的高度
                return config.maxScroll - nowDistance;
            }
            else {
                return 0;
            }
        },
        scrollToDistance(scrollParams) {
            // 获取配置文件
            this.setScrollConfig();
            if (scrollParams.x) {
                // scrollParams.x = Unit.unit(scrollParams.x,this._scrollConfig.x.maxScroll);
            }
            if (scrollParams.y) {
                // scrollParams.y = Unit.unit(scrollParams.y,this._scrollConfig.y.maxScroll);
            }
            return this._scrollApi.animateTo(scrollParams);
        },
        scrollTo(scrollParams) {
            // 获取配置文件
            this.setScrollConfig();
            if (scrollParams.x) {
                scrollParams.x = Unit.unit(scrollParams.x, this._scrollConfig.x.maxScroll);
                scrollParams.x = scrollParams.x - this._scrollConfig.x.x;
            }
            if (scrollParams.y) {
                scrollParams.y = Unit.unit(scrollParams.y, this._scrollConfig.y.maxScroll);
                scrollParams.y = scrollParams.y - this._scrollConfig.y.y;
            }
            return this._scrollApi.animateTo(scrollParams);
        },
        scrollToEnd(scrollParams = {}, all = false) {
            // 获取配置文件
            this.setScrollConfig();
            let newScrollParams = scrollParams;
            if (this.scrollY && this._scrollConfig.y.y < this._scrollConfig.y.maxScroll) {
                newScrollParams.y = this._scrollConfig.y.maxScroll;
                return !all && this.scrollTo(newScrollParams);
            }
            if (this.scrollX && this._scrollConfig.x.x < this._scrollConfig.x.maxScroll) {
                newScrollParams.x = this._scrollConfig.x.maxScroll;
            }
            return this.scrollTo(newScrollParams);
        },
        scrollToStart(scrollParams = {}, all) {
            let newScrollParams = scrollParams;
            if (this.scrollY && this._scrollConfig.y.y > 0) {
                newScrollParams.y = this._scrollConfig.y.y * -1;
                console.log(newScrollParams);
                return !all && this.scrollTo(newScrollParams);
            }
            if (this.scrollX && this._scrollConfig.x.x > 0) {
                newScrollParams.x = this._scrollConfig.y.x * -1;
            }
            return this.scrollTo(newScrollParams);
        }
    }
};
