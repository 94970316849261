let defaultOption = {
    module: 'pages/home',
    path: '',
    footerDir: ''
};
function getOptionValue(option, key) {
    return option[key] || defaultOption[key];
}
export default function (src, option = {}) {
    let images = ['https://img.yunkust.com/img/images'];
    let module = getOptionValue(option, 'module');
    if (module) {
        images.push(module);
    }
    let path = getOptionValue(option, 'path');
    if (path) {
        if (typeof path === 'object') {
            images.push(...path);
        }
        else {
            images.push(path);
        }
    }
    let footerDir = getOptionValue(option, 'footerDir');
    if (footerDir) {
        images.push(footerDir);
    }
    images.push(src);
    return images.join('/');
}
