import globalUnit from '$utils/unit/unit';
export default {
    data() {
        return {
            // 图片
            imageSize: undefined,
            // 外层容器
            containerSize: undefined,
            // 图标
            iconSize: undefined
        };
    },
    computed: {
        useIconSize() {
            if (this.iconSize && this.iconSize.width > 0 && this.imageSize.height > 0) {
                return this.iconSize;
            }
            else {
                return undefined;
            }
        },
        useImageSize() {
            if (this.imageSize && this.imageSize.width > 0 && this.imageSize.height > 0) {
                return this.imageSize;
            }
            else {
                return undefined;
            }
        },
        useContainerSize() {
            if (this.containerSize && this.containerSize.width > 0 || this.containerSize && this.containerSize.height > 0) {
                return {
                    width: this.containerSize.width + (this.borderWidth ? globalUnit.unitPx(this.borderWidth) * 2 : 0),
                    height: this.containerSize.height + (this.borderWidth ? globalUnit.unitPx(this.borderWidth) * 2 : 0),
                    margin: this.borderWidth * 2
                };
            }
            else {
                return undefined;
            }
        },
        useGetBodySize() {
            return this.getSizeFailBody && this.display === undefined;
        }
    },
    watch: {
        src: function () {
            if (this.useSrc !== this.src) {
                return this.installSize();
            }
        },
        display(value) {
            if (value && this.container && this.containerSize && this.containerSize.width <= 0 && this.containerSize.height <= 0) {
                this.containerSize = this.getElementSize(this.container);
                /* 如果图片加载成功 */
                if (this.status === this.imageStatus.success
                    &&
                        this.imageSize
                    &&
                        this.imageSize.width <= 0
                    &&
                        this.imageSize.height <= 0) {
                    this.imageSize = this.getElementSize(this.image);
                }
            }
        }
    },
    methods: {
        /*
        *   返回 预设置 size
        *   如果没有开启预设置且高度和宽度有一个小于 0 将为 undefined
        * */
        getPresetSize() {
            if (this.preset && this.presetWidth > 0 && this.presetHeight > 0) {
                return {
                    width: this.presetWidth,
                    height: this.presetHeight
                };
            }
            else {
                return undefined;
            }
        },
        /*
        *   返回 Element 标签的 Size
        * */
        getElementSize(el) {
            if (el) {
                let size = {
                    // @ts-ignore
                    width: el.offsetWidth,
                    // @ts-ignore
                    height: el.offsetHeight
                };
                // 如果都不可见触发
                if (size.width <= 0 && size.height <= 0) {
                    // 如果启用去body里获取
                    if (this.useGetBodySize) {
                        size = this.getBodySize(el);
                    }
                }
                // 返回格式大小
                return size;
            }
            else {
                return undefined;
            }
        },
        /* 通过全局获得尺寸
        *   该方式针对 百分百获取存在问题
        * */
        getBodySize(el) {
            if (el) {
                // @ts-ignore
                let useEl = el.cloneNode();
                useEl.setAttribute('class', el.getAttribute('class') + ' __v-image-get-size');
                document.body.append(useEl);
                let size = {
                    width: useEl.clientWidth,
                    height: useEl.clientHeight
                };
                document.body.removeChild(useEl);
                return size;
            }
            else {
                return undefined;
            }
        },
        /* 通过全局获得尺寸 */
        /*
        *   设置 图片 尺寸
        * */
        setImageSize(el) {
            let size = this.getElementSize(el);
            if (size) {
                // 设置 图片尺寸
                this.imageSize = size;
            }
            return size !== undefined;
        },
        /*
        *   初始化size
        * */
        installSize() {
            // 设置 容器 大小
            this.containerSize = this.getElementSize(this.container);
            // 获取预设置
            let presetSize = this.getPresetSize();
            // 如果存在预设 设置 图片 size
            if (presetSize)
                this.imageSize = presetSize;
            else
                this.imageSize = undefined;
            // 初始化样式状态
            this.setStatus(this.imageStatus.loading);
            // 设置本次加载的src
            return this.setImageSrc(this.src);
        }
    },
    mounted() {
        return this.installSize();
    },
    created() {
        if (this.initialization) {
            this.setImageSrc(this.src, false);
        }
    }
};
