var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Provide, Vue } from "vue-property-decorator";
let vAnimateItem = class vAnimateItem extends Vue {
    constructor() {
        super(...arguments);
        this.timingAnimates = [];
    }
    registerTiming(callback) {
        this.timingAnimates && this.timingAnimates.push(callback);
    }
};
__decorate([
    Inject()
], vAnimateItem.prototype, "register", void 0);
__decorate([
    Provide()
], vAnimateItem.prototype, "registerTiming", null);
vAnimateItem = __decorate([
    Component({
        inject: ['register'],
        mounted() {
            return this.register(this.$refs['animate-item'], () => {
                this.timingAnimates && this.timingAnimates.map((item) => item && item());
                this.timingAnimates = undefined;
            });
        }
    })
], vAnimateItem);
export default vAnimateItem;
