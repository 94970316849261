import config from "$config/config";
export default {
    $notify(message) {
        if (config.app.$store.state.config.mobile) {
            return config.app.$message(message);
        }
        else {
            return config.app.$notify(message);
        }
    }
};
