var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from "vue-property-decorator";
import imageOption from '../option/image';
let default_1 = class extends Vue {
    constructor() {
        super(...arguments);
        this.imageOption = imageOption;
        // 是否处于打开状态
        this.open = false;
    }
    openMobileMenu() {
        if (!this.open) {
            if (this.height === 0) {
                this.height = this.$refs.headerMenu.offsetHeight;
            }
            this.open = true;
        }
    }
    toggleMobileMenu() {
        return this.open ? this.hideMobileMenu() : this.openMobileMenu();
    }
    hideMobileMenu() {
        if (this.open) {
            this.open = false;
        }
    }
    // 跳转
    router(item) {
        this.hideMobileMenu();
        return this.$router.push(item.name);
    }
};
default_1 = __decorate([
    Component({
        computed: {
            title() {
                if (this.$route.meta && this.$route.meta.nav) {
                    return this.$route.meta.nav.title;
                }
                return '';
            }
        }
    })
], default_1);
export default default_1;
