// @ts-ignore
import verification from "../type/verification";
import toastModal from '../tool/toast';
export default {
    // 为空的校验
    empty: function (value) {
        if (value === undefined || value === null || value !== value) {
            return false;
        }
        else if (typeof value === 'string') {
            value = this.handle.trim(value);
            return value !== '';
        }
        else {
            return true;
        }
    },
    // 手机号验证
    mobile: function (value) {
        if (!value || typeof value === 'object')
            return false;
        else
            value = String(value);
        return /^1\d{10}$/.test(value);
    },
    // 校验item
    verificationItem(data, toast) {
        if (data.rules !== undefined) {
            let resultRules;
            if (typeof data.rules === 'string') {
                resultRules = { empty: data.rules };
            }
            else {
                resultRules = data.rules;
            }
            for (let key in resultRules) {
                // @ts-ignore
                if (resultRules.hasOwnProperty(key) && this[key] && !this[key](data.value, data)) {
                    let value = resultRules[key] || data.placeholder || data.label;
                    // 如果不通过,是否自动提示
                    if (toast) {
                        let rulesToast = typeof toast === 'boolean' ? '$notify' : toast;
                        // @ts-ignore
                        if (toastModal[rulesToast]) {
                            // @ts-ignore
                            toastModal[rulesToast]({
                                title: '消息提示',
                                message: value
                            });
                        }
                    }
                    return {
                        verification: false,
                        value,
                        type: key
                    };
                }
            }
            return {
                verification: true,
                value: data.value
            };
        }
        return {
            verification: true,
            value: data.value
        };
    },
    // 校验
    verification(data, toast) {
        let resultData;
        if (verification.isObject(data))
            resultData = [data];
        else
            resultData = data;
        let result = {};
        for (let i = 0, count = resultData.length; i < count; i++) {
            let useResult = this.verificationItem(resultData[i], toast);
            if (!useResult.verification) {
                return useResult;
            }
            else {
                let useKey = resultData[i].exportKey || resultData[i].key;
                if (useKey) {
                    result[useKey] = useResult.value;
                }
            }
        }
        return {
            verification: true,
            value: result
        };
    },
    // 校验返回 Promise
    verificationPromise(data, toast) {
        return new Promise((resolve, reject) => {
            let result = this.verification(data, toast);
            return result.verification ? resolve(result.value) : reject(result.value);
        });
    },
    handle: {
        trim: function (value) {
            value = value || '';
            if (typeof value !== 'string')
                value += '';
            return value.replace(/^\s*|\s*$/, '');
        }
    }
};
