var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import config from '$config/config';
import vHeader from '$views/header';
import vFooter from '$views/footer';
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.first = true;
    }
};
App = __decorate([
    Component({
        components: {
            vHeader,
            vFooter
        },
        created() {
            config.app = this;
            this.$store.dispatch('installConfig');
        },
        mounted() {
            setTimeout(() => {
                this.first = false;
            }, 300);
        }
    })
], App);
export default App;
