export default {
    unit(size, unit = 'px', units = ['width', 'height', 'top', 'left', 'right', 'bottom']) {
        units.map((item) => {
            // @ts-ignore
            if (typeof size[item] === 'number') {
                // @ts-ignore
                size[item] += unit;
            }
        });
        return size;
    }
};
