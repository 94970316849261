export default {
    methods: {
        emitScrollUpper: function (axis) {
            return this.$emit('scroll-upper', axis);
        },
        emitScrollLower: function (axis) {
            return this.$emit('scroll-lower', axis);
        },
        emitScroll: function (config) {
            return this.$emit('scroll', config);
        }
    },
    created() {
        // x是否执行了到达底部
        this._lowerThresholdXStatus = false;
        // y是否执行了到达底部
        this._lowerThresholdYStatus = false;
        // x是否执行了到达顶部
        this._upperThresholdXStatus = false;
        // y是否执行了到达顶部
        this._upperThresholdYStatus = false;
    }
};
