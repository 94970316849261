export default {
    methods: {
        // 播放视频
        play() {
            if (this.video) {
                return this.image.play();
            }
        },
        // 暂停
        pause() {
            if (this.video) {
                return this.image.pause();
            }
        },
        // 获取自定义
        getVideoElement() {
            if (this.video) {
                return this.image;
            }
        }
    }
};
