import props from '../props';
import require_image from "$utils/images/require_image";
export default {
    name: "v-local-image",
    computed: {
        moduleSrc() {
            console.log(require_image(this.src, this));
            return require_image(this.src, this);
        }
    },
    props,
};
