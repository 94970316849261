export default function () {
    return [
        {
            placeholder: '称呼姓名',
            key: 'name'
        },
        {
            placeholder: '联系电话',
            key: 'mobile'
        },
        {
            placeholder: '留下您的项目类型及预算即可获取报价',
            mobileComponent: 'textarea',
            key: 'price'
        }
    ];
}
