var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Provide, Vue } from "vue-property-decorator";
let vAnimate = class vAnimate extends Vue {
    constructor() {
        super(...arguments);
        // 动画模块
        this.animateModels = [];
    }
    // 注册模块
    register(el, callback) {
        // @ts-ignore
        let offsetHeight = el.offsetHeight;
        setTimeout(() => {
            this.animateModels.push({
                offsetTop: Math.max(this.offsetTop(el), 0),
                height: offsetHeight,
                callback: callback
            });
            this.complete();
        }, 300);
    }
    // 完成
    complete() {
        clearTimeout(this.completeTime);
        this.completeTime = setTimeout(() => {
        }, 10);
    }
    // 获取offsetTop
    offsetTop(el) {
        let offsetTop = 0;
        while (el) {
            // @ts-ignore
            offsetTop += el.offsetTop;
            // @ts-ignore
            if (el.offsetParent && el.offsetParent.getAttribute('id') === 'appMain') {
                // @ts-ignore
                el = null;
            }
            else {
                // @ts-ignore
                el = el.offsetParent;
            }
        }
        return offsetTop;
    }
    // 滚动触发
    animate(scroll) {
        if (!this.animateModels || this.animateModels.length <= 0)
            return;
        let height = scroll.y.height;
        let y = scroll.y.y + height;
        this.animateModels = this.animateModels.filter((item, index) => {
            if (this.$store.state.config.mobile) {
                if (y >= item.offsetTop) {
                    item.callback && item.callback();
                    return false;
                }
            }
            else {
                if (y >= item.offsetTop && y >= (item.offsetTop + item.height) && scroll.y.y <= item.offsetTop) {
                    item.callback && item.callback();
                    return false;
                }
            }
            return true;
            // this.animateModels
        });
    }
};
__decorate([
    Provide()
], vAnimate.prototype, "register", null);
vAnimate = __decorate([
    Component({
        mounted() {
        }
    })
], vAnimate);
export default vAnimate;
