export default [
    {
        label: '关于我们',
        message: '长期致力于PP订制开发、微信开发、网站建设，了解客户所需所想，直击需求痛点！我们一直与时俱进，用心做好每一个产品，并努力实现客户价值最大化，让客户更满意！'
    },
    {
        label: '我们能做什么',
        data: [
            {
                label: 'APP订制开发'
            },
            {
                label: '微信小程序订制'
            },
            {
                label: '网站公众号建设'
            },
            {
                label: '平台快速搭建'
            }
        ]
    },
    {
        label: '为什么选择我们',
        data: [
            {
                label: '成熟的原生系统'
            },
            {
                label: '自主源码搭建'
            },
            {
                label: '专业开发'
            },
            {
                label: '保证工期、按时交付'
            },
            {
                label: '正规大型企业'
            },
            {
                label: '协助三方资料对接'
            }
        ]
    }
];
