// 是否为 单位
const numberEmValidator = function (value) {
    // 转为字符串
    value = value.toString();
    // 执行校验是否以containerStyle数字开头
    return /^[.\d]+[a-z%]*/i.test(value);
};
export default {
    // 高度
    height: {
        type: [Number, String],
        default: '100%',
        validator: numberEmValidator
    },
    // 高度
    minHeight: {
        type: [Number, String],
        default: 'auto'
    },
    // 宽度
    width: {
        type: [Number, String],
        default: '100%',
        validator: numberEmValidator
    },
    // 是否启用flex布局系列之 flex ,自动通过 flex-group 占领剩余的空间
    flex: {
        type: Boolean,
        default: false
    },
    //  Y轴滚动
    scrollY: {
        type: Boolean,
        default: true
    },
    // X轴滚动
    scrollX: {
        type: Boolean,
        default: false
    },
    // x轴位置
    scrollLeft: {
        type: [Number, String],
        default: 0,
        validator: numberEmValidator
    },
    // y轴位置
    scrollTop: {
        type: [Number, String],
        default: 0,
        validator: numberEmValidator
    },
    // 距离顶部多远触发
    upperThreshold: {
        type: Number,
        default: 80
    },
    // 距离底部多远触发
    lowerThreshold: {
        type: Number,
        default: 80
    },
    // 兼容的滚动条安全尺寸
    securitySize: {
        type: Number,
        default: 0
    },
    // 滚动条类型
    scrollType: {
        type: String,
        default: 'none'
    },
    // 是否触发外部滚动
    behavior: {
        type: String,
        default: 'auto'
    },
    // 监听
    monitor: {
        type: Boolean,
        default: false
    }
};
